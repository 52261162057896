<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :allowNone="true"
        :organization="salesOrder.organization.id"
        :filterable="true"
        :showAll="false"
        :disabled="!!salesOrder.id"
        @organizationChanged="
          (organizationId) => {
            salesOrder.organization.id = organizationId;
            salesOrder.allowedLocations = [];
            salesOrder.recipient.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="salesOrder.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="salesOrder.organization.id"
        @locationsChanged="
          (locations) => {
            salesOrder.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      :label="`${$t('SALES_ORDERS.EXPIRATION_TIME')} (*)`"
      :placeholder="$t('SALES_ORDERS.EXPIRATION_TIME')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="salesOrder.expiration_time"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.expiration_time" />

    <base-input :label="`${$t(`SALES_ORDERS.SELECT_CUSTOMERS`)} (*)`">
      <customer-selector
        :allowNone="true"
        :customer="salesOrder.recipient.id"
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesOrder.organization.id"
        :disabled="!!salesOrder.id"
        @customerChanged="
          (customerId, customer) => {
            salesOrder.recipient.id = customerId;
            if (customer) {
              salesOrder.billing_entity_type = customer.customer_type;
              salesOrder.billing_firstname = customer.firstname;
              salesOrder.billing_lastname = customer.lastname;
              salesOrder.billing_company_name = customer.company_name;
              salesOrder.billing_email = customer.email;
              salesOrder.billing_country = customer.country;
              salesOrder.billing_state = customer.state;
              salesOrder.billing_city = customer.city;
              salesOrder.billing_zipcode = customer.zipcode;
              salesOrder.billing_address = customer.address;
            }
            onFormChanged();
          }
        "
      />
    </base-input>

    <h2 class="mt-5">{{ $t("COMMON.NOTE") }}</h2>
    <html-editor v-model="salesOrder.excerpt" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <h2 class="mt-5">{{ $t("COMMON.BILLING_INFORMATIONS") }}</h2>
    <base-input
      :label="$t('COMMON.TYPE')"
      :placeholder="$t('COMMON.TYPE')"
      @change="onFormChanged"
    >
      <el-select name="Type" v-model="salesOrder.billing_entity_type">
        <el-option
          :value="BILLING_ENTITY_TYPE_INDIVIDUAL"
          :label="$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')"
        />
        <el-option
          :value="BILLING_ENTITY_TYPE_COMPANY"
          :label="$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')"
        />
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.billing_entity_type" />

    <div
      class="row"
      v-if="
        salesOrder.billing_entity_type == BILLING_ENTITY_TYPE_INDIVIDUAL
      "
    >
      <div class="col">
        <base-input
          :label="$t('COMMON.FIRSTNAME')"
          v-model="salesOrder.billing_firstname"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_firstname" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.LASTNAME')"
          v-model="salesOrder.billing_lastname"
        />
        <validation-error :errors="apiValidationErrors.billing_lastname" />
      </div>
    </div>

    <base-input
      :label="$t('COMMON.COMPANY_NAME')"
      v-model="salesOrder.billing_company_name"
      @change="onFormChanged"
      v-if="salesOrder.billing_entity_type == BILLING_ENTITY_TYPE_COMPANY"
    />
    <validation-error :errors="apiValidationErrors.billing_company_name" />

    <base-input
      :label="$t('COMMON.EMAIL')"
      v-model="salesOrder.billing_email"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.billing_email" />

    <div class="row">
      <div class="col">
        <base-input
          :label="$t('COMMON.COUNTRY')"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="salesOrder.billing_country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                salesOrder.billing_country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_country" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.STATE')"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="salesOrder.billing_country"
            :state="salesOrder.billing_state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                salesOrder.billing_state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_state" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.CITY')"
          v-model="salesOrder.billing_city"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_city" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.ZIPCODE')"
          v-model="salesOrder.billing_zipcode"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_zipcode" />
      </div>
    </div>

    <base-input
      :label="$t('COMMON.ADDRESS')"
      v-model="salesOrder.billing_address"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.billing_address" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          salesOrder.id
            ? $t("SALES_ORDERS.EDIT_SALES_ORDER")
            : $t("SALES_ORDERS.ADD_SALES_ORDER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    CustomerSelector,
    CountrySelector,
    StateSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["salesOrderData", "formErrors", "loading"],

  data() {
    let salesOrderData = { ...this.salesOrderData };
    salesOrderData = this.$fillUserOrganizationData(salesOrderData);

    return {
      salesOrder: salesOrderData,
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let salesOrderData = cloneDeep(this.salesOrder);
      salesOrderData.expiration_time = moment(
        salesOrderData.expiration_time
      ).toISOString();
      salesOrderData = this.$fillUserOrganizationData(salesOrderData);
      delete salesOrderData.salesInvoice;
      if (!salesOrderData.billing_company_name) {
        salesOrderData.billing_company_name = "N/A";
      }
      if (!salesOrderData.billing_firstname) {
        salesOrderData.billing_firstname = "N/A";
      }
      if (!salesOrderData.billing_lastname) {
        salesOrderData.billing_lastname = "N/A";
      }
      this.$emit("salesOrderSubmitted", salesOrderData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    salesOrderData(salesOrderData) {
      if (salesOrderData) {
        this.salesOrder = {
          ...this.salesOrder,
          ...cloneDeep(salesOrderData),
        };
        if (!this.salesOrder.organization) {
          this.salesOrder.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
