var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.salesOrder.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.salesOrder.id},on:{"organizationChanged":function (organizationId) {
          _vm.salesOrder.organization.id = organizationId;
          _vm.salesOrder.allowedLocations = [];
          _vm.salesOrder.recipient.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.LOCATION'))),"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.salesOrder.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.salesOrder.organization.id},on:{"locationsChanged":function (locations) {
          _vm.salesOrder.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),_c('base-input',{attrs:{"label":((_vm.$t('SALES_ORDERS.EXPIRATION_TIME')) + " (*)"),"placeholder":_vm.$t('SALES_ORDERS.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":function () {
          _vm.onFormChanged();
        }},model:{value:(_vm.salesOrder.expiration_time),callback:function ($$v) {_vm.$set(_vm.salesOrder, "expiration_time", $$v)},expression:"salesOrder.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}}),_c('base-input',{attrs:{"label":((_vm.$t("SALES_ORDERS.SELECT_CUSTOMERS")) + " (*)")}},[_c('customer-selector',{attrs:{"allowNone":true,"customer":_vm.salesOrder.recipient.id,"filterable":true,"showAll":false,"filterOrganization":_vm.salesOrder.organization.id,"disabled":!!_vm.salesOrder.id},on:{"customerChanged":function (customerId, customer) {
          _vm.salesOrder.recipient.id = customerId;
          if (customer) {
            _vm.salesOrder.billing_entity_type = customer.customer_type;
            _vm.salesOrder.billing_firstname = customer.firstname;
            _vm.salesOrder.billing_lastname = customer.lastname;
            _vm.salesOrder.billing_company_name = customer.company_name;
            _vm.salesOrder.billing_email = customer.email;
            _vm.salesOrder.billing_country = customer.country;
            _vm.salesOrder.billing_state = customer.state;
            _vm.salesOrder.billing_city = customer.city;
            _vm.salesOrder.billing_zipcode = customer.zipcode;
            _vm.salesOrder.billing_address = customer.address;
          }
          _vm.onFormChanged();
        }}})],1),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.salesOrder.excerpt),callback:function ($$v) {_vm.$set(_vm.salesOrder, "excerpt", $$v)},expression:"salesOrder.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_INFORMATIONS")))]),_c('base-input',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":_vm.onFormChanged}},[_c('el-select',{attrs:{"name":"Type"},model:{value:(_vm.salesOrder.billing_entity_type),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_entity_type", $$v)},expression:"salesOrder.billing_entity_type"}},[_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_INDIVIDUAL,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')}}),_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_COMPANY,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')}})],1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_entity_type}}),(
      _vm.salesOrder.billing_entity_type == _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.FIRSTNAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesOrder.billing_firstname),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_firstname", $$v)},expression:"salesOrder.billing_firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_firstname}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.LASTNAME')},model:{value:(_vm.salesOrder.billing_lastname),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_lastname", $$v)},expression:"salesOrder.billing_lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_lastname}})],1)]):_vm._e(),(_vm.salesOrder.billing_entity_type == _vm.BILLING_ENTITY_TYPE_COMPANY)?_c('base-input',{attrs:{"label":_vm.$t('COMMON.COMPANY_NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesOrder.billing_company_name),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_company_name", $$v)},expression:"salesOrder.billing_company_name"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_company_name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EMAIL')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesOrder.billing_email),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_email", $$v)},expression:"salesOrder.billing_email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_email}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.COUNTRY'),"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.salesOrder.billing_country,"filterable":true,"showAll":false},on:{"countryChanged":function (country) {
              _vm.salesOrder.billing_country = country;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_country}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.STATE'),"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.salesOrder.billing_country,"state":_vm.salesOrder.billing_state,"filterable":true,"showAll":false},on:{"stateChanged":function (state) {
              _vm.salesOrder.billing_state = state;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_state}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.CITY')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesOrder.billing_city),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_city", $$v)},expression:"salesOrder.billing_city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_city}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.ZIPCODE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesOrder.billing_zipcode),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_zipcode", $$v)},expression:"salesOrder.billing_zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_zipcode}})],1)]),_c('base-input',{attrs:{"label":_vm.$t('COMMON.ADDRESS')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesOrder.billing_address),callback:function ($$v) {_vm.$set(_vm.salesOrder, "billing_address", $$v)},expression:"salesOrder.billing_address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_address}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.salesOrder.id ? _vm.$t("SALES_ORDERS.EDIT_SALES_ORDER") : _vm.$t("SALES_ORDERS.ADD_SALES_ORDER"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }